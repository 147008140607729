<template>
  <div>
    <b-card title="Settings">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row v-if="setting">
            <b-col cols="12">
              <b-row>
                <b-col cols="12">
                  <!-- setting of type media_array -->
                  <div v-if="setting.data_type == 'media_array'">
                    <p>
                      {{
                        setting.attribute.replace(/([A-Z,'_'])/g, ' ').trim()
                      }}
                    </p>

                    <!-- draggable -->
                    <draggable
                      :list="setting.value"
                      tag="ul"
                      group="media"
                      class="list-group list-group-flush cursor-move"
                    >
                      <b-row
                        v-for="(data, index) in setting.value"
                        :key="index"
                      >
                        <b-col cols="1">
                          <feather-icon icon="AlignJustifyIcon" class="" />
                        </b-col>
                        <b-col cols="6">
                          <div></div>

                          <b-img
                            class="w-auto"
                            style="max-width: 75%"
                            :src="data.image"
                          />
                        </b-col>
                        <b-col cols="4">
                          <!-- image data -->
                          <b-form-group
                            class=""
                            label="image"
                            :label-for="setting.attribute"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="setting.attribute"
                              rules="required"
                            >
                              <b-form-file
                                v-model="file"
                                @change="ChangeImageValue($event, index)"
                                :state="errors.length > 0 ? false : null"
                                accept="image/*"
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                          <!-- alt data -->
                          <b-form-group
                            class=""
                            label="alt"
                            :label-for="setting.attribute"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="setting.attribute"
                              rules="required"
                            >
                              <b-form-input
                                :id="setting.attribute + index"
                                v-model="data.alt"
                                :state="errors.length > 0 ? false : null"
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                          <!-- link data -->
                          <b-form-group
                            class=""
                            label="link"
                            :label-for="setting.attribute"
                          >
                            <b-form-input
                              :id="setting.attribute + index"
                              v-model="data.link"
                            />
                          </b-form-group>
                        </b-col>
                        <!-- Remove Button -->
                        <b-col lg="3" md="3" class="mb-50">
                          <b-button
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            v-show="index != 0"
                            variant="outline-danger"
                            class="mt-0 mt-md-2"
                            @click="removeMediaFromSetting(index)"
                          >
                            <feather-icon icon="XIcon" class="mr-25" />
                            <span>Remove</span>
                          </b-button>
                        </b-col>
                        <hr class="w-100 border-1 border-gray my-1" />
                      </b-row>
                    </draggable>
                  </div>
                </b-col>
                <!-- add new button -->
                <b-col cols="3">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    @click="addNewMediaToSetting"
                  >
                    <feather-icon icon="PlusIcon" class="mr-25" />
                    <span>Add New</span>
                  </b-button>
                </b-col>
                <hr class="w-100 border-1 border-gray my-1" />

                <b-col cols="12"> </b-col>

                <b-col lg="4" md="2" class="my-1">
                  <validation-provider
                    #default="{ errors }"
                    :name="setting.attribute"
                    rules="required"
                  >
                    <b-form-group
                      label="Active"
                      label-for="Active"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        v-model="setting.active"
                        :clearable="false"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="boolOptions"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12"> </b-col>
                <b-col lg="4" md="2" class="my-1">
                  <b-button
                    variant="primary"
                    class="mx-1"
                    @click="updateSetting($event, setting)"
                    >Submit</b-button
                  >
                  <b-button
                    variant="secondary"
                    class="mx-1"
                    :to="{ path: '/settings/index' }"
                    >Close</b-button
                  >
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="$store.state.Setting.showDismissibleAlert"
      @dismissed="$store.state.Setting.showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul
          v-for="(values, index) in $store.state.Setting.errors_back"
          :key="index"
        >
          <li v-for="(value, index) in values" :key="index">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>


<script>
import draggable from 'vuedraggable'
import { required, url } from '@validations'

import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
    Prism,
    draggable,
  },
  mixins: [heightTransition],
  data() {
    return {
      required,
      url,
      editableIndex: null,
    }
  },
  computed: {
    setting() {
      return this.$store.state.Setting.setting
    },

    permissionsAccess() {
      return this.$store.state.authPermissions
    },
    boolOptions() {
      return this.$store.state.Setting.boolOptions
    },
    file: {
      get() {
        return this.$store.state.Setting.file
      },
      set(payload) {
        this.$store.commit('Setting/setFile', payload)
      },
      // return this.$store.state.Setting.file
    },
    // settingType: {

    //   get() {
    //     return this.$store.state.Setting.settingType;
    //   },
    //   set(payload) {
    //     this.$store.commit("updateSettingType", payload);
    //   }
    // },
  },

  async created() {
    await this.$store.dispatch('Setting/getSetting', this.$route.params.id)
  },
  methods: {
    // add new setting data
    addNewMediaToSetting() {
      this.setting.value.push({
        image: null,
        alt: null,
        link: null,
      })
    },
    // remove media from array
    removeMediaFromSetting(index) {
      if (this.setting.value.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.setting.value.splice(index, 1)
      }
    },

    ChangeImageValue(e, index) {
      const image = e.target.files[0]
      if (e != null) {
        this.setting.value[index].image = URL.createObjectURL(image)

        var fileReader = new FileReader()
        fileReader.readAsDataURL(image)

        fileReader.onload = (e) => {
          this.setting.value[index].imageBase64 = e.target.result
            .split(',')
            .map((item) => item.trim())[1]
        }
      }
    },

    async updateSetting(e, item) {
      if (item.data_type == 'boolean') {
        if (item.value == 'true') {
          item.value = true
        } else {
          item.value = false
        }
      }
      if (item.data_type == 'file' || item.data_type == 'image') {
        if (this.file == null) {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'File Cannot be null!',
            showConfirmButton: false,
            timer: 1500,
          })
          return
        }

        // item.value = this.file.value
      }
      await this.$store.dispatch('Setting/updateSetting', item)
      if (this.$store.state.Setting.showDismissibleAlert == false) {
        this.$swal({
          position: 'center',
          icon: 'success',
          title: 'Your work has been saved',
          showConfirmButton: false,
          timer: 1500,
        })

        this.$router.push({ path: '/settings/index' })
      } else {
        this.$swal({
          position: 'center',
          icon: 'error',
          title: 'Error!',
          showConfirmButton: false,
          timer: 1500,
        })
      }
    },
  },
}
</script>

